<template>
  <v-row justify="center" v-if="showCookieBanner" style="position: absolute">
    <v-dialog v-model="showCookieBanner" width="auto" persistent>
      <v-card class="cookie-bar">
        <v-card-text class="text-center">
          <v-icon icon="mdi-cookie" />
          We use cookies for site authentication, improving your browsing
          experience and analyzing our website traffic.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn primary class="bg-green" @click="cookieOk"
            >Accept Cookies</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<style scoped>
.cookie-bar {
  min-width: 400px;
  border-radius: 3px;
}
</style>

<script lang="ts" setup>
import { bootstrap } from "vue-gtag";
import { ref } from "vue";
import { useCookies } from "@vueuse/integrations/useCookies";
const CONSENT_VERSION = 1; // changing this number causes the cookie banner to appear again
const STORAGE_KEY = "cookies-accepted";

const cookies = useCookies();
const consentedVersion = cookies.get(STORAGE_KEY);
const status = consentedVersion !== CONSENT_VERSION;
const showCookieBanner = ref(status);
function cookieOk() {
  bootstrap().then();
  cookies.set(STORAGE_KEY, CONSENT_VERSION, { secure: true, path: "/" });
  showCookieBanner.value = false;
}
</script>
