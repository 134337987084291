<template>
  <div v-if="dashboard && dashboard.displays.length > 0">
    <BaseLayoutView
      :pageTitle="'Dashboard: ' + dashboard.title"
      :titleToolTip="dashboard.description"
      :showFeedback="true"
    >
      <div v-if="dashboard.type === DashboardTypes.BASIC">
        <ReportDisplayComponent
          v-for="display in dashboard.displays"
          :key="JSON.stringify(display)"
          :display="display"
        ></ReportDisplayComponent>
      </div>
    </BaseLayoutView>
  </div>
  <div v-else-if="dashboard && dashboard.displays.length === 0">
    <BaseLayoutView :pageTitle="'Welcome to Evolve24'"
      ><h1>No reports found</h1>
      <p>
        We didn't find any reports for you to view, please
        <a
          href="https://evolve24.com/anticipatory-intelligence-contact/"
          target="support"
          >contact support</a
        >
        for more information.
      </p></BaseLayoutView
    >
  </div>
  <div v-else-if="!loadingStore.loading">
    <error404-component></error404-component>
  </div>
  <div v-else-if="loadingStore.loading">
    <BaseLayoutView :pageTitle="''"></BaseLayoutView>
  </div>
</template>

<script lang="ts" setup>
import BaseLayoutView from "@/modules/layouts/BaseLayoutComponent.vue";
import { useRoute } from "vue-router";
import {
  DashboardTypes,
  ReportStatus,
  useReportStore,
} from "@/modules/reports/ReportStore";
import type { Dashboard } from "@/modules/reports/ReportStore";
import { ref, watch } from "vue";
import type { Ref } from "vue";
import { storeToRefs } from "pinia";
import ReportDisplayComponent from "@/modules/reports/ReportDisplayComponent.vue";
import useLoadingStore from "@/modules/loading/LoadingStore";
import Error404Component from "@/modules/error404/Error404Component.vue";

const reportStore = useReportStore();
const route = useRoute();
const dashboard: Ref<Dashboard | null> = ref(null);
const { status } = storeToRefs(reportStore);
const loadingStore = useLoadingStore();
switch (status.value) {
  case ReportStatus.loaded:
    setDashboardUrlID(route.params.urlId as string);
    break;
}

// Watchers to reload report if status or route changes
watch(status, (val: ReportStatus) => {
  if (val === ReportStatus.loaded) {
    setDashboardUrlID(route.params.urlId as string);
  }
});
watch(route, (val) => {
  setDashboardUrlID(val.params.urlId as string);
});

function setDashboardUrlID(urlId: string) {
  dashboard.value = reportStore.findDashboard(urlId);
}
</script>
