<template>
  <div v-if="props.options" class="ma-auto" :style="{ width: width + 'px' }">
    <iframe
      id="vizDash"
      :src="src"
      :style="{ width: width + 'px', height: height + 'px' }"
    ></iframe>
  </div>
</template>
<script setup lang="ts">
import useAuthStore from "@ev24/eud-shared-ui/src/store/AuthStore";
import { computed, onMounted, ref } from "vue";
import type { Ref } from "vue";
import frameSize, { combineDefaults } from "@/libraries/FrameSize";
const authStore = useAuthStore();
const props = defineProps<{
  options: { [key: string]: any };
  frameWidth: number;
  frameHeight: number;
}>();
const src: Ref<string> = ref("");
onMounted(() => {
  src.value = `${import.meta.env.VITE_DASH_VIZ}/${
    props.options?.viz_id
  }/check-and-redirect?viz-token=${authStore.authInfo.elwa_jwt}`;
});

const defaultSizes = combineDefaults(props.options, {
  minWidth: 1000,
  maxWidth: 2500,
  minHeight: 750,
  maxHeight: 1500,
  height: 800,
});

const width = computed(() => {
  return frameSize("width", props.frameWidth, defaultSizes);
});

const height = computed(() => {
  return frameSize("height", props.frameHeight, defaultSizes);
});
</script>
