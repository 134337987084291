import { defineStore } from "pinia";
import { ref } from "vue";

export const useLoadingStore = defineStore("loading", () => {
  const loading = ref(false);
  let loadingItems: string[] = [];
  function show(loadingCompName: string) {
    loadingItems.push(loadingCompName);
    loading.value = true;
  }
  function hide(loadingCompName: string) {
    if (loadingItems.includes(loadingCompName)) {
      const index = loadingItems.indexOf(loadingCompName);
      if (index > -1) {
        loadingItems.splice(index, 1);
      }
      if (loadingItems.length === 0) {
        loading.value = false;
      }
    }
  }
  function forceHide() {
    loadingItems = [];
    loading.value = false;
  }
  // function add
  return { loading, show, hide, forceHide };
});

export default useLoadingStore;
