<template>
  <v-app class="g-navy-blue-180">
    <logo-component
      :height="60"
      class="pa-5 position-absolute"
    ></logo-component>
    <v-main class="d-flex align-center justify-center">
      <v-container
        class="d-flex align-stretch justify-center"
        fill-height
        full-height
      >
        <v-card width="500" v-if="!started">
          <v-toolbar color="primary-bar">
            <v-toolbar-title>Forgot Username</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form class="mr-10" v-model="valid">
              <v-text-field
                v-model="email"
                prepend-icon="person"
                name="login"
                label="Email"
                hint="Enter your email address"
                type="text"
                required
                @focus="errorMessage = ''"
                v-on:keydown.enter.prevent="sendEmail"
                :rules="rules.email"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <router-link to="/login" class="text-decoration-none">
              <v-btn color="primary">Cancel</v-btn>
            </router-link>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="sendEmail" :disabled="!valid"
              >Send Email</v-btn
            >
          </v-card-actions>
          <v-alert v-if="errorMessage" :text="errorMessage + ''" type="error">
          </v-alert>
        </v-card>
        <template v-else>
          <v-card width="500" v-if="!sent">
            <v-toolbar color="primary-bar">
              <v-toolbar-title>Sending email</v-toolbar-title>
            </v-toolbar>
            <v-card-actions>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-card-actions>
          </v-card>
          <v-card width="500" v-else>
            <v-toolbar color="primary-bar">
              <v-toolbar-title>Please check your email</v-toolbar-title>
            </v-toolbar>
            <v-card-actions>
              <v-btn color="primary" @click="reset">Try Again?</v-btn>
              <v-spacer></v-spacer>
              <router-link to="/login" class="text-decoration-none">
                <v-btn color="primary">Return to Login</v-btn>
              </router-link>
            </v-card-actions>
          </v-card>
        </template>
      </v-container>
    </v-main>
    <footer-component></footer-component>
  </v-app>
</template>
<style scoped></style>

<script setup lang="ts">
import FooterComponent from "@/modules/layouts/FooterComponent.vue";
import { ref } from "vue";
import LogoComponent from "@/modules/logo/LogoComponent.vue";
import useLoginStore from "@/modules/login/LoginStore";
import { useAlertStore } from "@ev24/eud-shared-ui/src/store/AlertStore";
import { rules } from "@/libraries/Validation";
const email = ref("");
const started = ref(false);
const sent = ref(false);
const errorMessage = ref("");
const valid = ref(false);
function reset() {
  sent.value = false;
  errorMessage.value = "";
  started.value = false;
}

const alertStore = useAlertStore();

async function sendEmail() {
  if (!email.value) {
    errorMessage.value = "Please enter your email address";
    return;
  }
  started.value = true;
  const loginStore = useLoginStore();
  try {
    await loginStore.forgotUsername(email.value);
    sent.value = true;
  } catch (e) {
    const error = e as { message?: string };
    const message = error.message
      ? error?.message
      : "An unknown error occurred";
    alertStore.error(message as string, "Error");
  }
}
</script>
