<template>
  <div v-if="props.options" class="ma-auto" :style="{ width: width + 'px' }">
    <tableau-viz
      v-if="authStore.authInfo.tabl_jwt"
      id="tableauViz"
      :width="width"
      :height="height"
      :src="props.options?.src"
      device="desktop"
      :token="authStore.authInfo.tabl_jwt"
    ></tableau-viz>
  </div>
</template>
<script setup lang="ts">
import useAuthStore from "@ev24/eud-shared-ui/src/store/AuthStore";
import { computed } from "vue";
import frameSize, { combineDefaults } from "@/libraries/FrameSize";
const authStore = useAuthStore();
const props = defineProps<{
  options: { [key: string]: any };
  frameWidth: number;
  frameHeight: number;
}>();
// temp hack fix because database is returning an invalid number
props.options.width = null;
const defaultSizes = combineDefaults(props.options, {
  minWidth: 800,
  maxWidth: 2500,
  maxHeight: 750,
  height: 800,
});
const width = computed(() => {
  return frameSize("width", props.frameWidth, defaultSizes);
});
const height = computed(() => {
  return frameSize("height", props.frameHeight, defaultSizes);
});
</script>
