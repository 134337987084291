// Theme toggle dark/light
import { useTheme } from "vuetify";
import { ref } from "vue";
import type { Ref } from "vue";
import { defineStore } from "pinia";

export enum ThemeName {
  light = "light",
  dark = "dark",
}
export const useThemeStore = defineStore("theme", () => {
  const THEME_KEY = "theme_name";
  const vuetifyTheme = useTheme();
  const theme: Ref<ThemeName> = ref(ThemeName.dark);

  function toggle() {
    const themeName =
      vuetifyTheme.global.name.value === ThemeName.light
        ? ThemeName.dark
        : ThemeName.light;
    setTheme(themeName);
  }

  function build() {
    const themeName = localStorage.getItem(THEME_KEY) as ThemeName;
    if (themeName === ThemeName.light || themeName === ThemeName.dark) {
      setTheme(themeName);
    }
  }

  function setTheme(themeName: ThemeName) {
    theme.value = themeName;
    vuetifyTheme.global.name.value = theme.value;
    localStorage.setItem(THEME_KEY, theme.value);
  }

  return {
    toggle,
    theme,
    build,
  };
});
