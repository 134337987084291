const rules = {
  text: [
    (value: string) => {
      if (value) return true;

      return "Field is required.";
    },
  ],
  email: [
    (value: string) =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
      "E-mail must be valid",
  ],
  password: [
    (value: string) => value.length >= 8 || "Minimum of 8 characters",
    (value: string) =>
      /[A-Z]/.test(value) || "Must contain at least one uppercase letter",
    (value: string) =>
      /[0-9]/.test(value) || "Must contain at least one number",
    (value: string) =>
      /[!@#$%^&*]/.test(value) ||
      "Must contain at least one special char (!@#$%^&*)",
  ],
};
export { rules };
