<template>
  <v-card color="lighten-1">
    <v-layout>
      <v-app-bar color="primary-bar" dark prominent height="40">
        <v-app-bar-nav-icon
          variant="text"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title dense>
          {{ pageTitle }}&nbsp;
          <v-tooltip
            v-if="titleToolTip"
            :text="titleToolTip"
            location="bottom"
            class="opaque-overlay"
          >
            <template v-slot:activator="{ props }">
              <v-icon size="x-small" icon="mdi-information" v-bind="props" />
            </template>
          </v-tooltip>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <FeedbackForm v-if="showFeedback"></FeedbackForm>
        <router-link to="/">
          <logo-component
            :height="30"
            class="mt-3 mr-10 ml-10"
            logoType="white"
          ></logo-component>
        </router-link>
        <v-menu offset-y anchor="bottom end">
          <template v-slot:activator="{ props }">
            <v-btn icon v-bind="props" height="30" width="30">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list density="compact">
            <v-list-item link>
              <v-list-item-title @click="themeStore.toggle">
                Theme: {{ themeStore.theme }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="logout">Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" bottom temporary app>
        <v-list density="compact">
          <v-list-item
            color="primary"
            to="/"
            @click="Analytics.click('left-nav-dashboard')"
            link
            dense
            no-gutter
          >
            <template v-slot:prepend>
              <v-icon color="primary" style="width: 24px">mdi-apps</v-icon>
            </template>
            <v-list-item-title class="ml-2">Home</v-list-item-title>
          </v-list-item>
          <v-list-subheader>REPORTS</v-list-subheader>

          <v-list-item
            color="primary"
            v-for="(item, i) in dashboardList"
            :key="i"
            :to="item.link"
            link
            dense
            no-gutter
            @click="Analytics.click('left-nav-report-' + item.link)"
          >
            <template v-slot:prepend>
              <IconDisplay
                :options="item.optionsMap"
                height="24px"
                width="24px"
                color="primary"
                :usage="IconUsage.SIDE_MENU"
              ></IconDisplay>
            </template>
            <v-list-item-title class="ml-2">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main style="min-height: 100vh">
        <v-container fluid>
          <slot></slot>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
  <FooterComponent></FooterComponent>
</template>
<style>
.opaque-overlay > .v-overlay__content {
  background: rgb(26, 26, 26) !important;
  color: #d5d5d5 !important;
  border: 1px solid rgba(108, 108, 108, 0.34);
}

.v-list-item__prepend {
  width: 24px !important;
}
</style>
<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import LogoComponent from "@/modules/logo/LogoComponent.vue";
import FooterComponent from "@/modules/layouts/FooterComponent.vue";
import { useLoginStore } from "@/modules/login/LoginStore";
import { IconUsage, useReportStore } from "@/modules/reports/ReportStore";
import { storeToRefs } from "pinia";
import { useThemeStore } from "@/modules/theme/ThemeStore";
import { useRouter } from "vue-router";
import Analytics from "@/libraries/Analytics";
import FeedbackForm from "@/modules/feedback/FeedbackForm.vue";
import IconDisplay from "@/modules/icon/IconDisplay.vue";
const router = useRouter();
const { pageTitle, titleToolTip } = defineProps<{
  pageTitle: string;
  titleToolTip?: string;
  showFeedback?: boolean;
}>();

// Loading reports and animation
const reportStore = useReportStore();

const { dashboardList } = storeToRefs(reportStore);
// variables

// drawer toggle
let drawer = ref(false);
let group = ref(null);

// logout button
const logout = () => {
  router.push(useLoginStore().logout().data);
};

const themeStore = useThemeStore();

// lifecycle hooks
onMounted(() => {});
watch(group, () => {
  drawer.value = false;
});
</script>
