<template>
  <v-card class="product" v-if="props.dashboard">
    <div class="image-container" @click="nav()">
      <IconDisplay
        :options="props.dashboard.optionsMap"
        :action="() => nav"
        height="200px"
        :usage="IconUsage.HOME_PAGE"
      ></IconDisplay>
    </div>

    <v-card-subtitle> {{ props.dashboard.title }} </v-card-subtitle>

    <v-card-actions>
      <v-btn class="btn-color" variant="text" @click="nav()"> View </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        :icon="showMore ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        @click="showMore = !showMore"
        class="btn-color"
      ></v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="showMore" class="exp-sec">
        <v-card-text>
          {{ props.dashboard.description }}
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<style scoped>
.image-container {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.product {
  background: white;
  color: #626262;
}
.v-card-subtitle {
  color: #000000 !important;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.exp-sec {
  box-shadow: inset 0px 1px 4px 0px #c5c2c2;
}
.btn-color {
  color: #e1694c;
}
</style>
<script lang="ts" setup>
import type { Dashboard } from "@/modules/reports/ReportStore";
import Analytics from "@/libraries/Analytics";
import { ref } from "vue";
import { useRouter } from "vue-router";
import IconDisplay from "@/modules/icon/IconDisplay.vue";
import { IconUsage } from "@/modules/reports/ReportStore";

const router = useRouter();
const showMore = ref(false);
const props = defineProps<{
  dashboard: Dashboard;
}>();

function nav() {
  Analytics.click("home-dashboard-" + props.dashboard.link);
  router.push(props.dashboard.link);
}
</script>
