<template>
  <!--- filters here --->
  <ReportTabComponent
    v-if="props.display.type === DisplayTypes.TABS"
    :display="props.display"
  ></ReportTabComponent>
  <ReportViewComponent
    v-if="props.display.type !== DisplayTypes.TABS"
    :report="props.display.reportDefs[0]"
  ></ReportViewComponent>
</template>
<script lang="ts" setup>
import type { Display } from "@/modules/reports/ReportStore";
import { DisplayTypes } from "@/modules/reports/ReportStore";
import ReportTabComponent from "@/modules/reports/ReportTabComponent.vue";
import ReportViewComponent from "@/modules/reports/ReportViewComponent.vue";

const props = defineProps<{
  display: Display;
}>();
</script>
