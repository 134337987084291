<template>
  <v-card v-if="props.report">
    <v-card-text ref="resizeEl" style="padding: 0">
      <div v-if="props.report.type === 'tableau_viz'">
        <ReportTableau
          :options="props.report.optionsMap?.tableau_viz"
          :frame-width="frameWidth"
          :frame-height="frameHeight"
        ></ReportTableau>
      </div>
      <div v-else-if="props.report.type == 'dash_viz'">
        <ReportVizDash
          :options="props.report.optionsMap?.dash_viz"
          :frame-width="frameWidth"
          :frame-height="frameHeight"
        ></ReportVizDash>
      </div>
      <div v-else-if="props.report.type == 'power_bi_viz'">
        <ReportPowerBi
          :options="props.report.optionsMap?.power_bi_viz"
          :frame-width="frameWidth"
          :frame-height="frameHeight"
        ></ReportPowerBi>
      </div>
      <div v-else>500 Error R1002: An error occurred, invalid report type.</div>
    </v-card-text>
  </v-card>
  <div v-else>
    500 Error R1001: An error occurred, unable to display report at this time.
  </div>
</template>
<script lang="ts" setup>
import type { Report } from "@/modules/reports/ReportStore";
import ReportTableau from "@/modules/report-tableau/ReportTableau.vue";
import ReportVizDash from "@/modules/report-viz-dash/ReportVizDash.vue";
import { useResizeObserver } from "@vueuse/core";
import { ref } from "vue";
import ReportPowerBi from "@/modules/report-power-bi/ReportPowerBi.vue";
const props = defineProps<{
  report: Report | undefined;
}>();

const resizeEl = ref(null);
const frameWidth = ref(0);
const frameHeight = ref(0);
useResizeObserver(resizeEl, (entries) => {
  frameWidth.value = entries[0].contentRect.width;
  frameHeight.value = entries[0].contentRect.height - 32;
});
</script>
