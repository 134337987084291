<template>
  <!-- TODO
  make zoom model a different component,
  multi-selector check boxes component,
  add loading animation for more rows,
  make search call APIs to filter option,
  make columns searchable individually,
  movable columns,
  save view settings,
  have multiple views,
  add view tabs,
  export csv option,
  multi viewing of report
  dense report view
  -->
  <v-container>
    <v-row no-gutters>
      <v-col cols="7">
        <multi-selector-component
          item-title="title"
          item-value="key"
          :items="apiHeaders"
          v-model="selectedColumns"
        ></multi-selector-component>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model:items-per-page="itemsPerPage"
          :headers="headers"
          :items="items"
          class="elevation-1"
          :options="options"
          :page="page"
          show-select
          v-model="selectedRows"
          @click:row="openModal"
          :search="search"
        >
          <template v-slot:body.prepend>
            <tr>
              <td v-for="header in headers" :key="header">
                <v-text-field
                  v-model="searches[header.key]"
                  type="text"
                  label="Test name"
                ></v-text-field>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="showModal" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Details</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <table class="modal">
            <tbody>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="itemKey in Object.keys(modalItem)" :key="itemKey">
                <td class="pr-4">{{ headerLabel(itemKey) }}</td>
                <td>{{ modalItem[itemKey] }}</td>
              </tr>
            </tbody>
          </table>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeModal">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  Selected items:
  <span v-for="item in selectedRows" :key="item"> {{ item.email }}, </span>
</template>
<style scoped>
table.modal {
  border-collapse: collapse;
}
table.modal tr > td {
  padding: 4px 8px;
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}
</style>
<script lang="ts" setup>
// import * as data from "@ev24/eud-shared-ui/src/grpc/__mocks__/MockTableData.json";
import { onMounted, ref, watch } from "vue";
import type { Ref } from "vue";
import MultiSelectorComponent from "@/modules/report-filters/MultiSelectorComponent.vue";
import type { DataTableHeader } from "./VDataTableTypes";

const items = ref({});
const options = ref({ pageCount: null });
const showModal = ref(false);
const modalItem = ref({});
const selectedColumns: Ref<string[]> = ref([]);
const selectedRows: Ref<string[]> = ref([]);
const page = ref(1);
const itemsPerPage = ref(10);
const searches = ref({});
const search = ref("");
//

onMounted(() => {
  selectedColumns.value = ["alphanumeric", "currency", "region", "postalZip"];
});

const apiHeaders = ref([
  { title: "ID", key: "alphanumeric", align: "start" as const },
  { title: "Cost", key: "currency", align: "start" as const },
  { title: "Category", key: "numberrange", align: "start" as const },
  { title: "Country", key: "country", align: "start" as const },
  { title: "Danger", key: "list", align: "start" as const },
  { title: "Region", key: "region", align: "start" as const },
  { title: "Address", key: "address", align: "start" as const },
  { title: "Email", key: "email", align: "start" as const },
  { title: "Phone", key: "phone", align: "start" as const },
  { title: "Text", key: "text", align: "start" as const },
  { title: "Postal Code", key: "postalZip", align: "start" as const },
]);

const headers: Ref<DataTableHeader[]> = ref(apiHeaders.value);

function filterDisplayHeaders(selected: string[]) {
  headers.value = apiHeaders.value.filter((header) => {
    return selected.includes(header.key);
  });
}
function headerLabel(key: string) {
  const found = apiHeaders.value.find((item) => item.key === key);
  if (found && found.title) return found.title;
  return key;
}

function openModal($event, { item }) {
  if ($event.target.type) return;
  modalItem.value = item.value;
  showModal.value = true;
}

function closeModal() {
  showModal.value = false;
  modalItem.value = {};
}

watch(selectedColumns, (first) => {
  filterDisplayHeaders(first);
});
</script>
