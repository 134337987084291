import { inject, ref, watch } from "vue";
import type { Ref } from "vue";
import { defineStore } from "pinia";
import { useIdle } from "@vueuse/core";
import type { UseIdleReturn } from "@vueuse/core";
import useLoadingStore from "@/modules/loading/LoadingStore";
import Analytics from "@/libraries/Analytics";
import { isIgnoredPath } from "@/router/Routes";

import useAuthStore from "@ev24/eud-shared-ui/src/store/AuthStore";
import { ReportStatus, useReportStore } from "@/modules/reports/ReportStore";
import { GRPC_SYM } from "@/plugins/GRPC";
import type { LunaLogin } from "@ev24/eud-shared-ui/src/grpc";
export enum LoginResponseTypes {
  Error,
  Redirect,
  Valid,
  Ignore,
}

export interface LoginResponse {
  type: LoginResponseTypes;
  data: string;
}

export const useLoginStore = defineStore("login", () => {
  const backPath: Ref<string> = ref("/");
  const authStore = useAuthStore();
  const reportStore = useReportStore();

  // real constants
  const LOGIN_ROUTE = "/login";
  const LOADING_NAME = "LOGIN_STORE";

  // Timer for inactivity
  const REFRESH_TIME = 5 * 60 * 1000; // 5 min
  const idleTimer: UseIdleReturn = useIdle(REFRESH_TIME);
  const showTimeOutScreen: Ref<boolean> = ref(false);
  const refreshTimer: Ref<ReturnType<typeof setTimeout> | null> = ref(null);
  let activityMonitorIsLive = false;
  const lunaLogin: LunaLogin = inject(GRPC_SYM.Login) as LunaLogin;

  function startActivityMonitor() {
    activityMonitorIsLive = true;
    showTimeOutScreen.value = false;
  }

  function stopActivityMonitor() {
    activityMonitorIsLive = false;
    showTimeOutScreen.value = false;
  }

  function startRefreshTimer(time: number) {
    stopAutoRefresh();
    refreshTimer.value = setTimeout(() => {
      const recentActivity =
        new Date().getTime() - idleTimer.lastActive.value < 1000 * 30;
      if (recentActivity) {
        refreshAuthTokens().then(() => startRefreshTimer(REFRESH_TIME));
      } else {
        console.log("checking if user is active for refresh");
        startRefreshTimer(1000);
      }
    }, time);
  }

  function stopAutoRefresh() {
    if (refreshTimer.value) {
      clearTimeout(refreshTimer.value);
      refreshTimer.value = null;
    }
  }

  function forgotUsername(username: string) {
    Analytics.action("sent-forgot-username");
    return lunaLogin.forgotUsername(username);
  }

  function continueUsing() {
    showTimeOutScreen.value = false;
  }

  async function login(
    username: string,
    password: string
  ): Promise<LoginResponse> {
    // get jwt
    const loadingStore = useLoadingStore();
    loadingStore.show(LOADING_NAME);
    try {
      await lunaLogin.login(username, password);
      loadingStore.hide(LOADING_NAME);
      startActivityMonitor();
      startRefreshTimer(REFRESH_TIME);
      const redirect = await pullReports();
      return Promise.resolve(redirect || redirectResponse(backPath.value));
    } catch (message: any) {
      loadingStore.hide(LOADING_NAME);
      return Promise.resolve(errorResponse(message));
    }
  }

  async function loginWithRefreshToken(): Promise<LoginResponse> {
    const loadingStore = useLoadingStore();
    loadingStore.show(LOADING_NAME);
    try {
      await lunaLogin.refreshToken(authStore.refreshCode);
      startRefreshTimer(REFRESH_TIME);
      startActivityMonitor();
      pullReports().then();
    } catch (e) {
      console.error(e);
    }
    loadingStore.hide(LOADING_NAME);
    return checkTokens();
  }

  function logout(): LoginResponse {
    // logout user
    stopAutoRefresh();
    stopActivityMonitor();
    authStore.$reset();
    reportStore.$reset();
    backPath.value = "/";
    useLoadingStore().show("LOGGING_OUT");
    setTimeout(() => {
      location.reload();
    }, 0);
    return redirectResponse(LOGIN_ROUTE);
  }

  function checkTokens(): LoginResponse {
    const validTokens = authStore.tokensAreValid();
    if (!validTokens) {
      const pathName = window.location.pathname;
      if (isIgnoredPath(pathName)) {
        return ignoreResponse();
      } else {
        setBackRoute(window.location.pathname);
        return redirectResponse(LOGIN_ROUTE);
      }
    }
    return validResponse();
  }

  function setBackRoute(lastPath: string) {
    backPath.value = !isIgnoredPath(lastPath) ? lastPath : "/";
  }

  function passwordReset(username: string) {
    Analytics.action("sent-reset-email");
    return lunaLogin.passwordReset(username);
  }

  function redirectResponse(path: string): LoginResponse {
    return {
      type: LoginResponseTypes.Redirect,
      data: path,
    };
  }

  async function refreshAuthTokens() {
    try {
      await lunaLogin.refreshToken(authStore.refreshCode);
    } catch (e) {
      console.error("Unable to refresh tokens", e);
    }
  }

  async function pullReports() {
    if (reportStore.status === ReportStatus.notLoaded) {
      await reportStore.fetch();
      if (reportStore.dashboardList.length === 1) {
        return redirectResponse(reportStore.dashboardList[0].link);
      } else {
        return null;
      }
    }
  }

  function errorResponse(message: string): LoginResponse {
    return {
      type: LoginResponseTypes.Error,
      data: message,
    };
  }

  function validResponse(): LoginResponse {
    return {
      type: LoginResponseTypes.Valid,
      data: "",
    };
  }
  function ignoreResponse(): LoginResponse {
    return {
      type: LoginResponseTypes.Ignore,
      data: "",
    };
  }

  watch(idleTimer.idle, (isIdle) => {
    if (!activityMonitorIsLive || showTimeOutScreen.value) {
      return;
    }
    if (isIdle) {
      showTimeOutScreen.value = true;
    }
  });

  return {
    login,
    logout,
    checkTokens,
    showTimeOutScreen,
    continueUsing,
    backPath,
    passwordReset,
    loginWithRefreshToken,
    forgotUsername
  };
});

export default useLoginStore;
