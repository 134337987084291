<template>
  <v-app class="g-navy-blue-180">
    <logo-component
      :height="60"
      class="pa-5 position-absolute"
    ></logo-component>
    <v-main class="d-flex align-center justify-center">
      <v-container
        class="d-flex align-stretch justify-center"
        fill-height
        full-height
      >
        <v-card width="500">
          <v-toolbar color="primary-bar">
            <v-toolbar-title>Please Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form class="mr-10">
              <v-text-field
                v-model="username"
                prepend-icon="mdi-account"
                name="username"
                label="Username"
                type="text"
                required
                @focus="errorMessage = ''"
                v-on:keydown.enter.prevent="login"
              ></v-text-field>
              <v-text-field
                v-model="password"
                prepend-icon="mdi-form-textbox-password"
                name="password"
                label="Password"
                type="password"
                required
                @focus="errorMessage = ''"
                v-on:keydown.enter.prevent="login"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <div class="d-flex justify-space-around mb-6">
            <v-btn class="w-75" color="primary" @click="login">Login</v-btn>
          </div>
          <v-card-actions class="d-flex justify-space-around">
            <v-btn
              class="pa-1 text-none font-weight-light"
              text="true"
              @click="resetPassword"
              color="primary"
              >Reset Password
            </v-btn>
            <v-btn
              class="pa-1 text-none font-weight-light"
              color="primary"
              @click="signUp"
              >Sign Up</v-btn
            >
            <v-btn
              class="pa-1 text-none font-weight-light"
              text="true"
              @click="forgotUsername"
              color="primary"
              >Forgot Username
            </v-btn>
          </v-card-actions>
          <v-alert v-if="errorMessage" :text="errorMessage + ''" type="error">
          </v-alert>
        </v-card>
      </v-container>
    </v-main>
    <FooterComponent></FooterComponent>
  </v-app>
</template>
<style scoped></style>

<script setup lang="ts">
import FooterComponent from "@/modules/layouts/FooterComponent.vue";
import { ref } from "vue";
import { useLoginStore, LoginResponseTypes } from "@/modules/login/LoginStore";
import LogoComponent from "@/modules/logo/LogoComponent.vue";
import { useRouter } from "vue-router";
import Analytics from "@/libraries/Analytics";
const username = ref("");
const password = ref("");
const errorMessage = ref("");
const router = useRouter();
//methods

const login = async () => {
  if (!!username.value && !!password.value) {
    try {
      const loginResponse = await useLoginStore().login(
        username.value,
        password.value
      );
      if (loginResponse.type === LoginResponseTypes.Redirect) {
        Analytics.action("login-successful");
        await router.push(loginResponse.data);
      } else if (loginResponse.type === LoginResponseTypes.Error) {
        Analytics.action("login-grpc-server-error");
        console.log("error", loginResponse.data);
        errorMessage.value = loginResponse.data;
      }
    } catch (e) {
      Analytics.action("login-server-error");
    }
  } else {
    errorMessage.value = "Please enter your username and password";
  }
};

async function signUp() {
  Analytics.click("sign-up");
  await router.push("sign-up");
}
async function resetPassword() {
  Analytics.click("password-reset");
  await router.push("password-reset");
}
async function forgotUsername() {
  Analytics.click("forgot-username");
  await router.push("forgot-username");
}
</script>
