<template>
  <v-select
    :items="props.items"
    label="Select"
    :item-value="props.itemValue"
    :item-title="props.itemTitle"
    multiple
    v-model="selected"
    hint="Unselect items"
  ></v-select>
</template>
<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  itemValue: string;
  itemTitle: string;
  items: [{ [key: string]: any }];
  modelValue: string[];
}>();

const emit = defineEmits(["update:modelValue"]);
const selected = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>
