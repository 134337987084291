import {
  LunaFeedback,
  LunaSupport,
  LunaLogin,
  LunaSignup,
  LunaSol,
} from "@ev24/eud-shared-ui/src/grpc";
import type {
  GRPCHeaders,
  GRPCOptions,
} from "@ev24/eud-shared-ui/src/interfaces/AuthInterface";
import type { App } from "vue";
export const GRPC_SYM = {
  Feedback: "LunaFeedback",
  Support: "LunaSupport",
  Sol: "LunaSol",
  Signup: "LunaSignup",
  Login: "LunaLogin",

  Headers: "headers",
  Options: "options",
  Host: "host",
};

const grpc = {
  install: (app: App, installOptions: { host: string }): void => {
    const headers: GRPCHeaders = {};
    const options: GRPCOptions = {};
    const host: string = installOptions.host;
    app.provide(GRPC_SYM.Headers, headers);
    app.provide(GRPC_SYM.Options, options);
    app.provide(GRPC_SYM.Host, host);
    app.provide(GRPC_SYM.Feedback, new LunaFeedback(host, headers, options));
    app.provide(GRPC_SYM.Support, new LunaSupport(host, headers, options));
    app.provide(GRPC_SYM.Sol, new LunaSol(host, headers, options));
    app.provide(GRPC_SYM.Signup, new LunaSignup(host, headers, options));
    app.provide(GRPC_SYM.Login, new LunaLogin(host, headers, options));
  },
};
export default grpc;
