<template>
  <div class="alert-container">
    <template v-for="message in alertStore.messages" :key="message.id">
      <v-alert
        v-if="message.type === AlertTypes.success"
        type="success"
        border="end"
        :title="message.title"
      >
        {{ message.text }}
      </v-alert>

      <v-alert
        v-if="message.type === AlertTypes.info"
        type="info"
        border="end"
        :title="message.title"
        >{{ message.text }}
      </v-alert>

      <v-alert
        v-if="message.type === AlertTypes.warning"
        type="warning"
        border="end"
        :title="message.title"
        >{{ message.text }}
      </v-alert>

      <v-alert
        v-if="message.type === AlertTypes.error"
        type="error"
        border="end"
        :title="message.title"
        >{{ message.text }}
      </v-alert>
    </template>
  </div>
</template>
<style scoped>
.alert-container {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 100000;
}
</style>
<script setup lang="ts">
import { AlertTypes } from "@ev24/eud-shared-ui/src/store/AlertStore";
import useAlertStore from "@ev24/eud-shared-ui/src/store/AlertStore";
const alertStore = useAlertStore();
</script>
