<template>
  <v-dialog v-model="showTimeOutScreen" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5"> Inactivity Detected </v-card-title>
      <v-card-text
        >For security reasons we haven't detected any activity recently, would
        you like to continue? Our system will automatically logout in
        <b>{{ seconds }}</b> seconds.</v-card-text
      >
      <v-card-actions>
        <v-btn color="secondary" @click="logoutUser()">Logout Now</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="continueUser()">Continue</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { useLoginStore } from "@/modules/login/LoginStore";
import { ref, watch } from "vue";
import type { Ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { addSeconds, compareDesc } from "date-fns";
const loginStore = useLoginStore();
const { showTimeOutScreen } = storeToRefs(loginStore);
const { continueUsing, logout } = loginStore;
const TIMER_AMOUNT = 60; // in seconds

let timer: ReturnType<typeof setTimeout> | null = null;
let timeIsUpDate: Date | null;
let seconds: Ref<number> = ref(TIMER_AMOUNT);
const router = useRouter();

watch(showTimeOutScreen, (value, oldValue) => {
  if (value && !oldValue) {
    startTimer();
  }
});

function startTimer() {
  // Display Timer
  timeIsUpDate = addSeconds(new Date(), TIMER_AMOUNT); // Extra security if the browser tab is put to sleep
  seconds.value = TIMER_AMOUNT;
  timer = setInterval(() => {
    seconds.value--;
    if (!checkLive() || seconds.value <= 0) {
      logoutUser();
      timeIsUpDate = null;
    }
  }, 1000);
}

function continueUser() {
  if (checkLive()) {
    continueUsing();
  } else {
    logoutUser();
  }
  if (timer) {
    clearInterval(timer);
  }
  timeIsUpDate = null;
}

function checkLive(): boolean {
  const nowDate = new Date();
  return !!(timeIsUpDate && compareDesc(nowDate, timeIsUpDate) === 1);
}
const logoutUser = async () => {
  if (timer) {
    clearInterval(timer);
    timeIsUpDate = null;
  }
  console.log("logout", logout().data);
  await router.push(logout().data);
};
</script>
