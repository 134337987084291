<template>
  <v-app>
    <router-view v-if="showPage" />
    <activity-prompt></activity-prompt>
    <alert-message-component></alert-message-component>
    <cookie-disclaimer-component></cookie-disclaimer-component>
    <loading-animation-component></loading-animation-component>
  </v-app>
</template>

<script setup lang="ts">
import { LoginResponseTypes, useLoginStore } from "@/modules/login/LoginStore";
import ActivityPrompt from "@/modules/login/ActivityPrompt.vue";
import AlertMessageComponent from "@/modules/alerts/AlertMessageComponent.vue";
import LoadingAnimationComponent from "@/modules/loading/LoadingAnimationComponent.vue";
import { useThemeStore } from "@/modules/theme/ThemeStore";
import { useRouter } from "vue-router";
import CookieDisclaimerComponent from "@/modules/layouts/CookieDisclaimerComponent.vue";
import { ref } from "vue";
const router = useRouter();
const showPage = ref(false);

const loginStore = useLoginStore();
//Check refresh code
try {
  loginStore.loginWithRefreshToken().then((loginResponse) => {
    if (loginResponse.type === LoginResponseTypes.Redirect) {
      router.push(loginResponse.data);
    }
    showPage.value = true;
  });
} catch (e) {
  console.log(e);
}

//** load theme style
const themeStore = useThemeStore();
themeStore.build();
</script>
