export interface Sizes {
  minWidth?: number | null;
  maxWidth?: number | null;
  width?: number | null;

  minHeight?: number | null;
  maxHeight?: number | null;
  height?: number | null;
}

type sizeKeys = { min: keyof Sizes; max: keyof Sizes };
const widthDimensions: sizeKeys = {
  min: "minWidth",
  max: "maxWidth",
};

const heightDimensions: sizeKeys = {
  min: "minHeight",
  max: "maxHeight",
};

export function combineDefaults(
  options: Sizes,
  defaults: {
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    height?: number;
    width?: number;
  }
): Sizes {
  return {
    minWidth: options.minWidth ?? defaults.minWidth ?? null,
    maxWidth: options.maxWidth ?? defaults.maxWidth ?? null,
    width: options.width ?? defaults.width ?? null,
    minHeight: options.minHeight ?? defaults.minHeight ?? null,
    maxHeight: options.maxHeight ?? defaults.maxHeight ?? null,
    height: options.height ?? defaults.height ?? null,
  };
}

export default function frameSize(
  dimension: "width" | "height",
  currentSize: number,
  sizes: Sizes
): number {
  const dimensionValue = sizes[dimension] ?? null;
  if (dimensionValue !== null) return dimensionValue;
  const dimensionNames =
    dimension === "width" ? widthDimensions : heightDimensions;
  const min = sizes[dimensionNames.min] ?? null;
  const max = sizes[dimensionNames.max] ?? null;
  if (currentSize === 0 || currentSize === null)
    return max || min || currentSize;
  if (min !== null && currentSize < min) return min;
  if (max !== null && max < currentSize) return max;
  return currentSize;
}
