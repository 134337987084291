import HomePage from "@/modules/home/HomePage.vue";
import LoginPage from "@/modules/login/LoginPage.vue";
import PasswordReset from "@/modules/login/PasswordReset.vue";
import ReportFrameComponent from "@/modules/reports/ReportDashboardComponent.vue";
import Error404Component from "@/modules/error404/Error404Component.vue";
import DemoComponent from "@/modules/DemoComponent.vue";
import SignUp from "@/modules/signup/SignUp.vue";
import ForgotUsername from "@/modules/login/ForgotUsername.vue";

export const Routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: SignUp,
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: PasswordReset,
  },
  {
    path: "/forgot-username",
    name: "forgot-username",
    component: ForgotUsername,
  },
  {
    path: "/dashboard/:urlId",
    name: "dashboard",
    component: ReportFrameComponent,
  },
  {
    path: "/demo/:tab?",
    name: "demo",
    component: DemoComponent,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: Error404Component,
  },
];
export const IGNORE_ROUTE_NAMES = ["login", "password-reset", "sign-up"];

export function isIgnoredPath(path: string) {
  for (const i in IGNORE_ROUTE_NAMES) {
    if (path.toLowerCase().includes(IGNORE_ROUTE_NAMES[i])) {
      return true;
    }
  }
  return false;
}
