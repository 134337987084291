<template>
  <v-app class="g-navy-blue-180">
    <logo-component
      :height="60"
      class="pa-5 position-absolute"
    ></logo-component>
    <v-main class="d-flex align-center justify-center">
      <v-container
        class="d-flex align-stretch justify-center"
        fill-height
        full-height
      >
        <v-form>
          <v-card width="500">
            <v-toolbar color="primary-bar">
              <v-toolbar-title>Sign Up</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-form v-model="valid">
                <v-text-field
                  v-model="username"
                  name="username"
                  label="Username"
                  type="text"
                  required
                  :error-messages="fieldErrorMessages.username"
                  @input="setFieldError('username', '')"
                  :rules="rules.text"
                ></v-text-field>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="first_name"
                      name="first_name"
                      label="First name"
                      type="text"
                      required
                      :error-messages="fieldErrorMessages.first_name"
                      :rules="rules.text"
                      @input="setFieldError('first_name', '')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="last_name"
                      name="last_name"
                      label="Last name"
                      type="text"
                      required
                      :rules="rules.text"
                      :error-messages="fieldErrorMessages.last_name"
                      @input="setFieldError('last_name', '')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  v-model="email"
                  name="email"
                  label="Email"
                  type="text"
                  :rules="rules.email"
                  :error-messages="fieldErrorMessages.email"
                  required
                  @input="setFieldError('email', '')"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  name="password"
                  label="Password"
                  type="password"
                  :rules="rules.password"
                  :error-messages="fieldErrorMessages.password"
                  required
                  @input="setFieldError('password', '')"
                >
                  <v-tooltip activator="parent" location="bottom">
                    <ul class="pl-2">
                      <li>Minimum of 8 characters</li>
                      <li>At least one uppercase letter</li>
                      <li>At least one number</li>
                      <li>At least one special character</li>
                    </ul>
                  </v-tooltip>
                </v-text-field>

                <v-text-field
                  v-model="sign_up_code"
                  name="sign_up_code"
                  label="Sign up code"
                  type="text"
                  required
                  :rules="rules.text"
                  :error-messages="fieldErrorMessages.sign_up_code"
                  @input="setFieldError('sign_up_code', '')"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" @click="backToLogin">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="signUp" :disabled="!valid"
                >Submit</v-btn
              >
            </v-card-actions>
            <v-alert v-if="errorMessage" :text="errorMessage + ''" type="error">
            </v-alert>
          </v-card>
        </v-form>
      </v-container>
    </v-main>
    <footer-component></footer-component>
  </v-app>
</template>
<style scoped></style>

<script setup lang="ts">
import FooterComponent from "@/modules/layouts/FooterComponent.vue";
import { inject, ref } from "vue";
import type { Ref } from "vue";
import LogoComponent from "@/modules/logo/LogoComponent.vue";
import { useRouter, useRoute } from "vue-router";
import Analytics from "@/libraries/Analytics";
import type LunaSignup from "@ev24/eud-shared-ui/src/grpc/LunaSignup";
import { useReCaptcha } from "vue-recaptcha-v3";
import useLoadingStore from "@/modules/loading/LoadingStore";
import { GRPC_SYM } from "@/plugins/GRPC";
import { getStrUrlParam } from "@/libraries/Url";
import type { E24UserAccountSignUpResponse } from "@ev24/proto-eud-luna-web-api/elwa_v1_pb";
import { rules } from "@/libraries/Validation";
const valid = ref(false);
interface FormFields {
  username?: string;
  first_name?: string;
  sign_up_code?: string;
  last_name?: string;
  password?: string;
  email?: string;
}

const route = useRoute();

const loadingStore = useLoadingStore();

const recaptcha = useReCaptcha();
const username = ref(getStrUrlParam("username"));
const first_name = ref(getStrUrlParam("first_name"));
const sign_up_code = ref(getStrUrlParam("code"));
const last_name = ref(getStrUrlParam("last_name"));
const password = ref("");
const email = ref(getStrUrlParam("email"));
const errorMessage = ref("");
const router = useRouter();
const fieldErrorMessages: Ref<FormFields> = ref({});
const lunaSignup: LunaSignup = inject(GRPC_SYM.Signup) as LunaSignup;

//methods
async function captcha() {
  // (optional) Wait until recaptcha has been loaded.
  if (recaptcha?.recaptchaLoaded) {
    await recaptcha.recaptchaLoaded();

    // Execute reCAPTCHA with action "login".
    return await recaptcha.executeRecaptcha("signup");
  }
  // Do stuff with the received token.
}

function validateEmail(email: string) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function setFieldError(field: keyof FormFields, message: string) {
  fieldErrorMessages.value[field] = message;
}
function clearFieldErrors() {
  fieldErrorMessages.value = {};
  errorMessage.value = "";
}
const signUp = async () => {
  loadingStore.show("sign-up-submit");
  const recaptchaToken = await captcha();
  clearFieldErrors();
  if (!valid.value) {
    errorMessage.value = "Please fill out all missing fields";
  } else if (!validateEmail(email.value)) {
    setFieldError("email", "Invalid Email address");
  } else {
    try {
      Analytics.click("signup-submitted");
      const signupResponse: E24UserAccountSignUpResponse.AsObject =
        (await lunaSignup.signUp(
          username.value,
          first_name.value + " " + last_name.value,
          email.value,
          password.value,
          sign_up_code.value,
          recaptchaToken || ""
        )) as E24UserAccountSignUpResponse.AsObject;

      if (parseInt(signupResponse.code) === 200) {
        await backToLogin();
      } else if (parseInt(signupResponse.code) === 422) {
        const fieldErrorMessage = JSON.parse(signupResponse.message);
        setFieldError(fieldErrorMessage[0], fieldErrorMessage[1]);
      } else {
        errorMessage.value = "An unknown error occurred: SU1002";
      }
    } catch (e) {
      if (e && typeof e.message !== "undefined") {
        console.error(e);
        errorMessage.value = e.message + "" + ": SU1003";
      }
      Analytics.action("signup-response-error");
    }
  }
  loadingStore.hide("sign-up-submit");
};

async function backToLogin() {
  await router.push("login");
}
</script>
