import { createApp } from "vue";
import router from "@/router/Router";
import vuetify from "@/plugins/Vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import { loadFonts } from "@/plugins/WebfontLoader";
import { createPinia } from "pinia";
import "./assets/main.css";
import { VueReCaptcha } from "vue-recaptcha-v3";

const pinia = createPinia();
import App from "@/App.vue";
import VueGtag from "vue-gtag";
import grpc from "@/plugins/GRPC";

loadFonts().then();

const app = createApp(App)
  .use(router)
  .use(vuetify)
  .use(pinia)
  .use(grpc, {
    host: import.meta.env.VITE_ELWA_API,
  })
  .use(VueAxios, axios)
  .use(VueReCaptcha, {
    siteKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_ID,
    loaderOptions: { autoHideBadge: true, useEnterprise: true },
  })
  .use(
    VueGtag,
    {
      bootstrap: false,
      appName: "eud-atlas-ui",
      pageTrackerScreenviewEnabled: true,
      config: { id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID },
    },
    router
  );

app.provide("axios", app.config.globalProperties.axios);
app.mount("#app");
