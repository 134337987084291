<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ props }">
      <v-btn
        class="mx-2 p-0"
        variant="flat"
        color="secondary-bar"
        size="x-small"
        v-bind="props"
        >Support
      </v-btn>
    </template>
    <v-form v-model="valid">
      <VCard>
        <VCardTitle>Evolve24 Support Form</VCardTitle>
        <VCardText>
          <VContainer>
            <v-row>
              <v-col>
                <VTextField
                  v-if="!elwaJwt"
                  v-model="fields.fullname"
                  label="Name"
                  required
                  :rules="rules.text"
                ></VTextField>
              </v-col>
              <v-col>
                <VTextField
                  v-if="!elwaJwt"
                  v-model="fields.email"
                  label="Email"
                  required
                  :rules="rules.email"
                ></VTextField>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <VTextarea
                  v-model="fields.problem"
                  label="What can we help you with?"
                  required
                  :rules="rules.text"
                ></VTextarea>
              </v-col>
            </v-row>
          </VContainer>
        </VCardText>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="close">
            Close
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="send"
            :disabled="!valid"
          >
            Send
          </v-btn>
        </v-card-actions>
      </VCard>
    </v-form>
  </v-dialog>
</template>
<script setup lang="ts">
import { inject, ref } from "vue";
import type { Ref } from "vue";
import Analytics from "@/libraries/Analytics";
import useAuthStore from "@ev24/eud-shared-ui/src/store/AuthStore";
import { useReCaptcha } from "vue-recaptcha-v3";
import useLoadingStore from "@/modules/loading/LoadingStore";
import type LunaSupport from "@ev24/eud-shared-ui/src/grpc/LunaSupport";
import type { SupportFields } from "@ev24/eud-shared-ui/src/grpc/LunaSupport";
import { useRoute } from "vue-router";
import { GRPC_SYM } from "@/plugins/GRPC";
import { rules } from "@/libraries/Validation";
const loadingStore = useLoadingStore();
const recaptcha = useReCaptcha();
const elwaJwt = useAuthStore().authInfo.elwa_jwt;
const route = useRoute();
const valid = ref(false);
const lunaSupport: LunaSupport = inject(GRPC_SYM.Support) as LunaSupport;

const fields: Ref<SupportFields> = ref({
  token: elwaJwt as string,
  related_to: window.location.href,
  fullname: "",
  email: "",
  problem: "",
});

const dialog: Ref<boolean> = ref(route.query.support === "true" || false);

function close() {
  Analytics.click("close-support-modal");
  dialog.value = false;
}

async function captcha() {
  // Wait until recaptcha has been loaded.
  if (recaptcha?.recaptchaLoaded) {
    await recaptcha.recaptchaLoaded();

    // Execute reCAPTCHA with action "login".
    return await recaptcha.executeRecaptcha("supportForm");
  }
}

const send = async () => {
  Analytics.click("send-support-ticket");
  loadingStore.show("sending-support");
  const recaptchaToken = await captcha();
  try {
    Analytics.click("support-sent");
    await lunaSupport.send(fields.value, recaptchaToken);
    fields.value.problem = "";
  } catch (e) {
    console.error(e);
    Analytics.action("support-response-error");
  }
  loadingStore.hide("sending-support");

  dialog.value = false;
};
</script>
