<template>
  <v-icon
    v-if="icon && icon.file_type === IconFileTypes.ICON"
    @click="clickAction"
    class="icon-main icon-style"
    :style="{
      height: props.height,
      width: props.width || 'auto',
      fontSize: props.height,
    }"
    :color="props.color"
    >{{ icon.path }}</v-icon
  >
  <v-img
    v-if="icon && icon.file_type !== IconFileTypes.ICON"
    :src="icon.path"
    @click="clickAction"
    :style="{ height: props.height, width: props.width || 'auto' }"
    class="icon-main img-style"
    :color="props.color"
  ></v-img>
</template>
<style scoped>
.icon-main {
  height: auto;
  width: auto;
  text-align: center;
  max-width: 100%;
  border-radius: 3px;
}
.img-style {
  background-color: white;
}
.icon-style {
}
</style>
<script setup lang="ts">
import type { DashboardOptions } from "@/modules/reports/ReportStore";
import { IconFileTypes, IconUsage } from "@/modules/reports/ReportStore";
import { computed } from "vue";
import { useThemeStore } from "@/modules/theme/ThemeStore";

const themeStore = useThemeStore();

const props = defineProps<{
  options: DashboardOptions;
  usage?: IconUsage;
  action?: () => {};
  height: string;
  width?: string;
  color?: string;
}>();

console.log(props.options);
function clickAction() {
  if (props.action) {
    props.action();
  }
}

const icon = computed(() => {
  let matchingUsage = null;
  let matchingTheme = null;
  let defaultIcon = null;

  if (!props.options || !props.options.icons) return null;
  for (const icon of props.options.icons) {
    //Perfect match check
    if (icon.usage === props.usage && icon.theme === themeStore.theme) {
      return icon;
    }
    if (
      icon.theme === themeStore.theme &&
      (!icon.usage || icon.usage === IconUsage.BASIC)
    ) {
      matchingTheme = icon;
    }
    if (icon.usage === props.usage) {
      matchingUsage = icon;
    }
    if (!icon.usage || icon.usage === IconUsage.BASIC) {
      defaultIcon = icon;
    }
  }
  if (matchingUsage) {
    return matchingUsage;
  }
  if (matchingTheme) {
    return matchingTheme;
  }
  return defaultIcon;
});
</script>
