import { event } from "vue-gtag";

export default class Analytics {
  static click(name: string) {
    event("click:" + name, { event_category: "click" });
  }
  static action(name: string) {
    event("action:" + name, { event_category: "action" });
  }
}
