<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" variant="text" size="x-small"
        ><v-icon>mdi-thumbs-up-down</v-icon> &nbsp; Send Feedback
      </v-btn>
    </template>
    <v-form v-model="valid">
      <VCard>
        <VCardTitle>Feedback</VCardTitle>
        <VCardText>
          We would love to hear any suggestions you have about our application.
          <VContainer>
            <VTextarea
              v-model="fields.comment"
              label="Comments"
              required
              :rules="rules.text"
            ></VTextarea>
          </VContainer>
        </VCardText>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="close">
            Close
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="send"
            :disabled="!valid"
          >
            Send
          </v-btn>
        </v-card-actions>
      </VCard>
    </v-form>
  </v-dialog>
</template>
<script setup lang="ts">
import { inject, ref } from "vue";
import type { Ref } from "vue";
import Analytics from "@/libraries/Analytics";
import useAuthStore from "@ev24/eud-shared-ui/src/store/AuthStore";
import useLoadingStore from "@/modules/loading/LoadingStore";
import type LunaFeedback from "@ev24/eud-shared-ui/src/grpc/LunaFeedback";
import type { FeedbackFields } from "@ev24/eud-shared-ui/src/grpc/LunaFeedback";
import { useRoute } from "vue-router";
import { GRPC_SYM } from "@/plugins/GRPC";
import { useReCaptcha } from "vue-recaptcha-v3";
import { rules } from "@/libraries/Validation";
const loadingStore = useLoadingStore();
const elwaJwt = useAuthStore().authInfo.elwa_jwt;
const route = useRoute();
const recaptcha = useReCaptcha();
const fields: Ref<FeedbackFields> = ref({
  token: elwaJwt as string,
  related_to: window.location.href,
  comment: "",
});

const valid = ref(false);
const lunaFeedback: LunaFeedback = inject(GRPC_SYM.Feedback) as LunaFeedback;
const dialog: Ref<boolean> = ref(route.query.feedback === "true" || false);

function close() {
  Analytics.click("close-feedback-modal");
  dialog.value = false;
}

async function captcha() {
  // Wait until recaptcha has been loaded.
  if (recaptcha?.recaptchaLoaded) {
    await recaptcha.recaptchaLoaded();

    // Execute reCAPTCHA with action "login".
    return await recaptcha.executeRecaptcha("feedbackForm");
  }
}

const send = async () => {
  Analytics.click("send-feedback-ticket");
  loadingStore.show("sending-feedback");
  const recaptchaToken = await captcha();
  try {
    Analytics.click("feedback-sent");
    await lunaFeedback.send(fields.value, recaptchaToken);
    fields.value.comment = "";
  } catch (e) {
    console.error(e);
    Analytics.action("feedback-response-error");
  }
  loadingStore.hide("sending-feedback");

  dialog.value = false;
};
</script>
