<template>
  <v-footer
    border
    color="secondary-bar"
    :app="true"
    bottom
    fixed
    padless
    :height="footerHeight"
  >
    <v-row justify="center" align="center" no-gutters>
      <span class="ml-3 mr-6" style="font-size: 12px"
        >© {{ new Date().getFullYear() }} by evolve24</span
      >

      <v-btn
        v-for="link in links"
        :key="link.url"
        class="mx-2"
        rounded
        :href="link.url"
        target="_blank"
        variant="flat"
        color="secondary-bar"
        size="x-small"
      >
        <template v-if="link.text">
          {{ link.text }}
        </template>
        <template v-if="link.icon">
          <v-icon>{{ link.icon }}</v-icon>
        </template>
      </v-btn>
      <SupportForm></SupportForm>
      <span class="v-btn--size-x-small">v{{ version }} </span>
    </v-row>
  </v-footer>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import type { Ref } from "vue";
import { useDisplay } from "vuetify";
import SupportForm from "@/modules/feedback/SupportForm.vue";

const links: Ref<
  {
    icon?: string;
    text?: string;
    url?: string;
    ariaLabel?: string;
  }[]
> = ref([
  {
    url: "https://twitter.com/evolve24",
    icon: "mdi-twitter",
    ariaLabel: "Follow us on Twitter",
  },
  {
    url: "https://www.linkedin.com/company/evolve24/",
    icon: "mdi-linkedin",
    ariaLabel: "Connect on LinkedIn",
  },
]);
const { smAndUp } = useDisplay();
const version = __APP_VERSION__;
const footerHeight = computed(() => (smAndUp.value ? 25 : 50));
</script>
