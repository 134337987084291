<template>
  <v-app class="g-navy-blue-180">
    <logo-component
      :height="60"
      class="pa-5 position-absolute"
    ></logo-component>
    <v-main class="d-flex align-center justify-center">
      <v-container
        class="d-flex align-stretch justify-center"
        fill-height
        full-height
      >
        <v-card class="w-100">
          <v-tabs v-model="tab" bg-color="primary">
            <v-tab to="/demo/0">Table Example</v-tab>
            <v-tab to="/demo/1">VChart JS Example</v-tab>
            <v-tab to="/demo/2">EChart JS Example</v-tab>
          </v-tabs>

          <v-card-text>
            <v-window v-model="tab" class="w-100">
              <v-window-item>
                <v-card>
                  <v-card-title>Table Example</v-card-title>
                  <v-card-item>
                    <report-table-component></report-table-component>
                  </v-card-item>
                </v-card>
              </v-window-item>

              <v-window-item>
                <bar-chart-js-component></bar-chart-js-component>
              </v-window-item>

              <v-window-item>
                <v-list>
                  <v-list-item v-for="theme in THEME_NAMES" :key="theme">
                    <v-btn @click="changeTheme(theme)">{{ theme }}</v-btn>
                  </v-list-item>
                </v-list>
                <EChartTemplateComponent></EChartTemplateComponent>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
    <footer-component></footer-component>
  </v-app>
</template>
<script lang="ts" setup>
import LogoComponent from "@/modules/logo/LogoComponent.vue";
import EChartTemplateComponent from "@/modules/report-echart/EChartTemplateComponent.vue";
import BarChartJsComponent from "@/modules/report-chartjs/BarChartJsComponent.vue";
import FooterComponent from "@/modules/layouts/FooterComponent.vue";
import ThemeMaker, {
  THEME_NAMES,
  ThemeName,
} from "@/modules/report-echart/echart-themes/ThemeMaker";
import { ref } from "vue";
import ReportTableComponent from "@/modules/report-table/ReportTableComponent.vue";
import { useRoute } from "vue-router";
const route = useRoute();
const tab = ref(parseInt(route.params.tab, 10));
function changeTheme(theme: ThemeName) {
  console.log("activating " + theme);
  ThemeMaker.activate(theme);
}
</script>
