<template>
  <v-tabs v-model="tab" class="nicer-tabs-container" v-if="loaded">
    <VTab
      color="primary"
      v-for="(reportStruct, index) in props.display.optionsMap.tab_struct as TabStruct[]"
      :key="reportStruct.id_report_def"
      class="nicer-tabs"
      :class="{ darkTab: vuetifyTheme.global.name.value === ThemeName.dark }"
    >
      {{
        !!reportStruct.tab_title
          ? reportStruct.tab_title
          : reportsByIndex[index].title
      }}&nbsp;

      <v-tooltip
        v-if="reportsByIndex[index].description"
        :text="reportsByIndex[index].description"
        location="bottom"
        class="opaque-overlay"
      >
        <template v-slot:activator="{ props }">
          <v-icon size="small" icon="mdi-information" v-bind="props" />
        </template>
      </v-tooltip>
    </VTab>
  </v-tabs>
  <div
    v-if="
      loaded && viewedTabs.includes(props.display.optionsMap.tab_struct[tab])
    "
    v-for="(reportStruct, index) in props.display.optionsMap.tab_struct"
    :key="reportStruct.id_report_def"
  >
    <report-display-component
      :class="{ showTab: showTab(index) }"
      class="hideTab"
      :report="reportsByIndex[index]"
    ></report-display-component>
  </div>
</template>
<style scoped>
.nicer-tabs {
  margin-right: 3px;
  padding: 0 6px;
  height: 34px !important;
  background: #d7d7d7;
}
.darkTab {
  background: #2e2e2e;
}
.nicer-tabs-container {
  height: 34px !important;
}
.showTab {
  display: block !important;
}
.hideTab {
  display: none;
}
</style>
<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import type { Ref } from "vue";
import type { Display, Report } from "@/modules/reports/ReportStore";
import ReportDisplayComponent from "@/modules/reports/ReportViewComponent.vue";
import useAuthStore from "@ev24/eud-shared-ui/src/store/AuthStore";
import { useLoginStore } from "@/modules/login/LoginStore";
import Analytics from "@/libraries/Analytics";
import { useTheme } from "vuetify";
import { ThemeName } from "@/modules/theme/ThemeStore";
const vuetifyTheme = useTheme();

const loaded = ref(false);

interface TabStruct {
  id_report_def: string;
  tab_title?: string;
}

const props = defineProps<{
  display: Display;
}>();
const tab = ref(0);
const viewedTabs = ref([props.display.optionsMap.tab_struct[tab.value]]);
const reportsByIndex: Ref<Report[]> = ref([]);

onMounted(() => {
  reportsByIndex.value = [];
  for (const tabStruct of props.display.optionsMap.tab_struct as TabStruct[]) {
    const report = findReport(tabStruct.id_report_def);
    if (report) {
      reportsByIndex.value.push(report);
    }
  }
  loaded.value = true;
});

watch(tab, () => {
  Analytics.click("report-tab-" + props.display.reportDefs[tab.value].title);
  if (
    !viewedTabs.value.includes(props.display.optionsMap.tab_struct[tab.value])
  ) {
    viewedTabs.value.push(props.display.optionsMap.tab_struct[tab.value]);
  }
  const authStore = useAuthStore();
  if (!authStore.tokensAreValid()) {
    const loginStore = useLoginStore();
    loginStore.logout();
  }
});
function showTab(index: number) {
  return tab.value === index;
}

function findReport(id: string): Report | undefined {
  return props.display.reportDefs.find((report) => report.id === id);
}
</script>
