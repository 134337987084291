// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  components: {
    ...components,
  },
  defaults: {
    VDataTable: {
      fixedHeader: true,
      noDataText: "Results not found",
    },
  },
  directives,
  theme: {
    defaultTheme: "dark",
    themes: {
      light: {
        dark: false,
        colors: {
          background: "#E5EFFF",
          "primary-bar": "#223475",
          "secondary-bar": "#4D55A3",
          primary: "#223475",
          "primary-lighten-1": "#3272EE",
          "primary-lighten-2": "#E5EFFF",
          "primary-lighten-3": "#edf2fd",
          secondary: "#4D55A3",
          "secondary-lighten-1": "#767EC1",
          error: "#ff2953",
          info: "#EB5F2C",
          success: "#4CAF50",
          warning: "#FB8C00",
          "card-color": "#efefef",
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: "#bcc8f3",
          "primary-bar": "#223475",
          "secondary-bar": "#4D55A3",
          "primary-lighten-1": "#3272EE",
          "primary-lighten-2": "#E5EFFF",
          "primary-lighten-3": "#edf2fd",
          secondary: "#9299da",
          "secondary-lighten-1": "#b0b6ea",
          error: "#ff2953",
          info: "#EB5F2C",
          success: "#4CAF50",
          warning: "#FB8C00",
          "card-color": "#464646",
        },
      },
    },
  },
});
