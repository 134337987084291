import HalloweenTheme from "@/modules/report-echart/echart-themes/HalloweenTheme";
import ShineTheme from "@/modules/report-echart/echart-themes/ShineTheme";
import EChart, { THEME_KEY } from "vue-echarts";
import * as echarts from "echarts";
import { provide } from "vue";
export interface DarkLightDefaults {
  text: string;
  subText: string;
  background: string;
}

const lightDefaults: DarkLightDefaults = {
  text: "#393939",
  subText: "#313131",
  background: "#ffffff",
};
const darkDefaults: DarkLightDefaults = {
  text: "#e3e3e3",
  subText: "#dadada",
  background: "#212121",
};

export enum ThemeName {
  Halloween = "halloween",
  Shine = "shine",
}

export const THEME_NAMES: ThemeName[] = [ThemeName.Halloween, ThemeName.Shine];

export default class ThemeMaker {
  static init() {
    echarts.registerTheme(
      ThemeMaker._registerName(ThemeName.Shine, false),
      ShineTheme.make(lightDefaults)
    );
    echarts.registerTheme(
      ThemeMaker._registerName(ThemeName.Shine, true),
      ShineTheme.make(darkDefaults)
    );
    echarts.registerTheme(
      ThemeMaker._registerName(ThemeName.Halloween, false),
      HalloweenTheme.make(lightDefaults)
    );
    echarts.registerTheme(
      ThemeMaker._registerName(ThemeName.Halloween, true),
      HalloweenTheme.make(darkDefaults)
    );
  }

  static activate(themeName: ThemeName, darkMode = true) {
    console.log(ECharts);
    provide(THEME_KEY, ThemeMaker._registerName(themeName, darkMode));
  }

  static _registerName(themeName: ThemeName, darkMode = true) {
    return themeName + (darkMode ? "-dark" : "-light");
  }
}
