<template>
  <div>
    <PowerBIReportEmbed
      :embed-config="sampleReportConfig"
      :phased-embedding="phasedEmbeddingFlag"
      :css-class-name="reportClass"
      :event-handlers="eventHandlersMap"
      @report-obj="setReportObj"
      :style="{ width: width + 'px', height: height + 'px' }"
    >
    </PowerBIReportEmbed>
  </div>
</template>

<script setup lang="ts">
import { models, Report, service, Embed } from "powerbi-client";
import type { IReportEmbedConfiguration } from "powerbi-client";
import "powerbi-report-authoring";

import { PowerBIReportEmbed } from "powerbi-client-vue-js";
import { computed, ref } from "vue";
import type { Ref } from "vue";
import frameSize, { combineDefaults } from "@/libraries/FrameSize";
import useAuthStore from "@ev24/eud-shared-ui/src/store/AuthStore";

const authStore = useAuthStore();

const props = defineProps<{
  options: { [key: string]: any };
  frameWidth: number;
  frameHeight: number;
}>();

// Flag which specifies whether to use phase embedding or not
const phasedEmbeddingFlag = false;

// CSS Class to be passed to the wrapper
const reportClass = computed(() => "report-container" + props.options.id);

let report: Ref<Report | null> = ref(null);

const sampleReportConfig = computed(() => {
  return {
    id: props.options.id,
    type: props.options.type,
    tokenType: models.TokenType.Embed,
    accessToken: authStore.authInfo.powerbi_jwt,
    settings: props.options.settings,
  };
}) as IReportEmbedConfiguration;

const eventHandlersMap = ref(
  new Map([
    ["loaded", () => console.log("Report has loaded")],
    ["rendered", () => console.log("Report has rendered")],
    [
      "error",
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
    ["visualClicked", () => console.log("visual clicked")],
    ["pageChanged", (event) => console.log(event)],
  ])
) as Ref<
  Map<
    string,
    (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null
  >
>;

/**
 * Assign Embed Object from Report component to report
 * @param value
 */
function setReportObj(value: Report) {
  report.value = value;
}

const defaultSizes = combineDefaults(props.options, {
  minWidth: 1000,
  maxWidth: 2500,
  minHeight: 750,
  maxHeight: 1500,
  height: 800,
});

const width = computed(() => {
  return frameSize("width", props.frameWidth, defaultSizes);
});

const height = computed(() => {
  return frameSize("height", props.frameHeight, defaultSizes);
});
</script>
