<template>
  <BaseLayoutView pageTitle="Home">
    <v-container class="grey lighten-5 home-layout">
      <v-row no-gutters>
        <v-col
          v-for="dashboard in dashboardList"
          :key="dashboard.id"
          class="pa-1"
          cols="3"
        >
          <DashboardCard :dashboard="dashboard"></DashboardCard>
        </v-col>
      </v-row>
    </v-container>
  </BaseLayoutView>
</template>
<style scoped>
.home-layout {
  max-width: 1200px;
}
</style>

<script setup lang="ts">
import BaseLayoutView from "@/modules/layouts/BaseLayoutComponent.vue";
import { useReportStore } from "@/modules/reports/ReportStore";
import { storeToRefs } from "pinia";
import DashboardCard from "@/modules/home/DashboardCard.vue";

const reportStore = useReportStore();
const { dashboardList } = storeToRefs(reportStore);
</script>
