import { defineStore } from "pinia";
import { inject, ref } from "vue";
import type { Ref } from "vue";
import useLoadingStore from "@/modules/loading/LoadingStore";
import type {
  EudUiDashboard,
  EudReportDef,
} from "@ev24/proto-eud-luna-web-api/elwa_v1_pb";
import type LunaSol from "@ev24/eud-shared-ui/src/grpc/LunaSol";
import type { EudResponseDashboards } from "@ev24/proto-eud-luna-web-api/elwa_v1_pb";
import keyValueArrayToObject from "@ev24/eud-shared-ui/src/utility/KeyValueArrayToObject";
import { GRPC_SYM } from "@/plugins/GRPC";
import type { ThemeName } from "@/modules/theme/ThemeStore";

export enum ReportStatus {
  notLoaded,
  loading,
  loaded,
  error,
}
const defaultReportIcons = [
  "mdi-chart-areaspline",
  "mdi-chart-areaspline-variant",
  "mdi-chart-bell-curve",
  "mdi-chart-bar",
  "mdi-chart-bar-stacked",
  "mdi-chart-bell-curve-cumulative",
];

export enum DashboardTypes {
  BASIC = "page_basic",
}

export enum IconFileTypes {
  SVG = "svg",
  IMG = "img",
  ICON = "icon",
}
export enum IconUsage {
  BASIC = "basic",
  SIDE_MENU = "side_menu",
  HOME_PAGE = "home_page",
}

export interface DashboardOptions {
  icons?: [
    {
      file_type: IconFileTypes;
      path: string;
      usage?: IconUsage;
      theme?: ThemeName;
    }
  ];
}

export interface Dashboard {
  id: string;
  link: string;
  type: string;
  title: string;
  urlId: string;
  description: string;
  optionsMap: DashboardOptions;
  displays: Array<Display>;
  reportDefs: Array<Report>;
}

export interface Display {
  type: string;
  optionsMap: { [key: string]: any };
  optionsReportDefOverrideMap: { [key: string]: any };
  reportDefs: Array<Report>;
}

export enum DisplayTypes {
  TABS = "tab_basic",
  BASIC = "basic",
}

export interface Report {
  id: string;
  type: string;
  dataType: string;
  title: string;
  description: string;
  optionsMap: { [key: string]: any };
  filterDefsList: Array<FilterDefs>;
}

export interface FilterDefs {
  id: string;
  type: string;
  title: string;
  valueJson: any;
  optionsMap: { [key: string]: any };
}

export const useReportStore = defineStore("reports", () => {
  const status: Ref<ReportStatus> = ref(ReportStatus.notLoaded);
  const dashboardList: Ref<Array<Dashboard>> = ref([]);
  const requestId: Ref<string> = ref("");
  const LOADING_NAME = "REPORT_STORE";
  const mock = ref(false);
  const lunaSol: LunaSol = inject(GRPC_SYM.Sol) as LunaSol;

  function $reset() {
    status.value = ReportStatus.notLoaded;
    dashboardList.value = [];
    requestId.value = "";
  }

  async function fetch() {
    const loadingStore = useLoadingStore();
    loadingStore.show(LOADING_NAME);
    status.value = ReportStatus.loading;
    try {
      const reportDefs =
        (await _reportDefs()) as EudResponseDashboards.AsObject;
      if (Object.prototype.hasOwnProperty.call(reportDefs, "payloadList")) {
        status.value = ReportStatus.loaded;
        requestId.value = reportDefs.requestId;
        dashboardList.value = parsePayload(reportDefs.payloadList);
      } else {
        status.value = ReportStatus.error;
      }
    } catch (e) {
      console.error(e);
      status.value = ReportStatus.error;
    }
    loadingStore.hide(LOADING_NAME);
  }

  function parsePayload(
    payloadList: Array<EudUiDashboard.AsObject>
  ): Array<Dashboard> {
    const parsedPayload = [];
    let defaultReportIcon = ref(0);
    for (const dashboard of payloadList) {
      const reportDefs = parseReports(dashboard.reportDefsList);
      const displays = parseDisplays(dashboard, reportDefs);
      const urlArray = dashboard.id.split("/");
      const urlId = urlArray[urlArray.length - 1];

      const parsedDashboard: Dashboard = {
        id: dashboard.id,
        link: "/dashboard/" + urlId,
        urlId: urlId,
        title: dashboard.title,
        type: dashboard.type,
        description: dashboard.description,
        optionsMap: parseDashboardOptions(
          dashboard.optionsMap,
          defaultReportIcon
        ),
        displays: displays,
        reportDefs: reportDefs,
      };

      parsedPayload.push(parsedDashboard);
    }
    return parsedPayload;
  }

  function parseDisplays(
    dashboard: EudUiDashboard.AsObject,
    reportDefs: Array<Report>
  ): Array<Display> {
    const displayList = [];
    for (const display of dashboard.uiDisplaysList) {
      const optionsMap = keyValueArrayToObject(display.optionsMap);

      const parsedDisplay: Display = {
        type: display.type,
        optionsMap: optionsMap,
        optionsReportDefOverrideMap: keyValueArrayToObject(
          display.optionsReportDefOverrideMap
        ),
        reportDefs: reportDefs,
      };
      displayList.push(parsedDisplay);
    }
    return displayList;
  }

  function parseReports(reports: Array<EudReportDef.AsObject>): Array<Report> {
    const parsedReports: Array<Report> = [];
    reports.forEach((report) => {
      const parsedReport = {
        id: report.id,
        title: report.title,
        type: report.type,
        dataType: report.dataType,
        description: report.description,
        optionsMap: keyValueArrayToObject(report.optionsMap),
        filterDefsList: report.filterDefsList.map((row) => {
          return {
            id: row.id,
            optionsMap: keyValueArrayToObject(row.optionsMap),
            title: row.title,
            type: row.type,
            valueJson: JSON.parse(row.valueJson),
          };
        }),
      };
      parsedReports.push(parsedReport);
    });

    return parsedReports;
  }

  function parseDashboardOptions(
    options: any,
    defaultReportIcon: Ref
  ): DashboardOptions {
    const parsedOptions = keyValueArrayToObject(options);
    if (!parsedOptions.icons) {
      parsedOptions.icons = [
        {
          file_type: IconFileTypes.ICON,
          path: defaultReportIcons[
            defaultReportIcon.value++ % defaultReportIcons.length
          ],
          usage: IconUsage.BASIC,
        },
      ];
    }
    return parsedOptions;
  }

  function findDashboard(urlId: string): Dashboard | null {
    return (
      dashboardList.value.find((dashboard: Dashboard) => {
        return dashboard.urlId === urlId;
      }) || null
    );
  }

  function _reportDefs() {
    return lunaSol.uiReportDef();
  }

  return {
    status,
    fetch,
    findDashboard,
    dashboardList,
    mock,
    $reset,
  };
});
